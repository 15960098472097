import { Notify } from 'quasar'

export function useConnectionQuality() {
  function checkConnectionQuality() {
    const message = {
      full: `Your internet connection is poor, and errors or unstable app performance may occur.<br> 
            Please check this or connect to a better Wi-Fi.`,
      short: 'Bad connection',
    }

    const isAlreadyShow = ref(false)

    if ('connection' in navigator) {
      // @ts-expect-error...
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection

      /**
       * Assess the quality of the internet connection. Logs messages to the console,
       * warning the user if the connection is slow.
       */
      const assessConnection = () => {
        const downlink = connection.downlink // connection speed
        const rtt = connection.rtt // connection latency
        const effectiveType = connection.effectiveType // connection type

        if (downlink < 1 || rtt > 300 || effectiveType === '2g' || effectiveType === 'slow-2g') {
          Notify.create({
            message: isAlreadyShow.value ? message.short : message.full,
            type: 'negative',
            position: 'top',
            timeout: isAlreadyShow.value ? 5000 : 10000,
            html: true,
          })
          isAlreadyShow.value = true
        } else {
          console.log(`Network speed: ${downlink} Mb/s, ping: ${rtt} ms`)
        }
      }

      assessConnection()

      connection.addEventListener('change', assessConnection)
    }
  }

  onMounted(() => {
    if (!import.meta.env.SSR) {
      if (document.readyState === 'complete') {
        checkConnectionQuality()
      } else {
        window.addEventListener('load', checkConnectionQuality)
      }
    }
  })
}
