<script setup lang="ts">
import '~/assets/scss/app.scss'

useConnectionQuality()

// For telegram
function preventCollapse() {
  if (window.scrollY === 0) {
    window.scrollTo(0, 1)
  }
}

if (typeof document !== 'undefined') {
  document.addEventListener('touchstart', preventCollapse)
}
</script>

<template>
  <router-view />
</template>
